import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BffCustomerWebSharedCoreModule } from '@bff/bff-customer-web/shared/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeNl, 'nl-NL', localeNlExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutingModule, BrowserModule, BffCustomerWebSharedCoreModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
