import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NOTIFICATION_FEATURE_KEY,
  NotificationState,
} from './notification.reducer';

// Lookup the 'Notification' feature state managed by NgRx
const getNotificationState = createFeatureSelector<NotificationState>(
  NOTIFICATION_FEATURE_KEY
);

const getFcmRegistrationCreating = createSelector(
  getNotificationState,
  (state) => state.fcmRegistrationCreating
);

const getFcmRegistrationCreateError = createSelector(
  getNotificationState,
  (state) => state.fcmRegistrationCreateError
);

const getFcmRegistrationRemoving = createSelector(
  getNotificationState,
  (state) => state.fcmRegistrationRemoving
);

const getFcmRegistrationRemoveError = createSelector(
  getNotificationState,
  (state) => state.fcmRegistrationRemoveError
);

export const notificationQuery = {
  getFcmRegistrationCreating,
  getFcmRegistrationCreateError,
  getFcmRegistrationRemoving,
  getFcmRegistrationRemoveError,
};
