import { HttpErrorResponse } from '@angular/common/http';
import { OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function catchApiError(): OperatorFunction<any, any> {
  return (source$) =>
    source$.pipe(
      catchError((err: HttpErrorResponse) => {
        throw err.error;
      })
    );
}
