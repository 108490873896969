import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

export abstract class ActionStatusResolverService {
  abstract resolve<T = Action>(
    actionToDispatch: Action,
    successType: string,
    failType: string,
    t?: number
  ): Observable<T>;
}
