import { DeviceType } from '../enums/device-type.enum';

export class DeviceTypeUtil {
  static toString(type: DeviceType | null): string {
    switch (type) {
      case DeviceType.Android:
        return 'Android';
      case DeviceType.Ios:
        return 'iOS';
      default:
        return '-';
    }
  }
}
