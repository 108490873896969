import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthFacade } from '@bff/shared/auth/data-access';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

export abstract class AbstractAuthInterceptor implements HttpInterceptor {
  protected constructor(public authFacade: AuthFacade) {}

  abstract logout(): void;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.addToken(request).pipe(
      switchMap((_request) =>
        next.handle(_request).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401(request, next);
            } else {
              return throwError(error);
            }
          })
        )
      )
    );
  }

  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.authFacade.accessToken$.pipe(
      first(),
      map((accessToken) => {
        return accessToken
          ? this.setTokenInAuthHeader(request, accessToken)
          : request;
      })
    );
  }

  private handle401(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const addToken$ = this.authFacade.refreshTokenInProgress$.pipe(
      first(),
      switchMap((refreshTokenInProgress) =>
        refreshTokenInProgress
          ? this.waitForRefreshAndAddToken(request, next)
          : this.refreshAndAddToken(request, next)
      )
    );
    return this.authFacade.accessToken$.pipe(
      first(),
      switchMap((token) => {
        if (token) {
          return addToken$;
        } else {
          return next.handle(request);
        }
      })
    );
  }

  private waitForRefreshAndAddToken(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authFacade.refreshToken$.pipe(
      first(),
      switchMap(() => this.addToken(request)),
      switchMap((r) => next.handle(r))
    );
  }

  private refreshAndAddToken(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authFacade.refreshToken().pipe(
      catchError((error) => {
        // if action is fail, resume
        return of(error);
      }),
      switchMap(() => this.authFacade.refreshTokenError$.pipe(first())),
      switchMap((error) => {
        if (error) {
          this.logout();
          return throwError(error);
        }
        return this.addToken(request).pipe(
          switchMap((req) => next.handle(req))
        );
      })
    );
  }

  private setTokenInAuthHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
