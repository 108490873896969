import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedCoreModule, StoreRootModule } from '@bff/shared/core';
import { SharedUtilTranslocoConfigModule } from '@bff/shared/util-transloco-config';
import { environment } from '@bff/shared/config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerAuthInterceptor } from './interceptors/customer-auth.interceptor';

@NgModule({
  imports: [
    CommonModule,
    SharedCoreModule,
    SharedUtilTranslocoConfigModule.forRoot(environment.production),
    StoreRootModule.forRoot(environment.production),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomerAuthInterceptor,
      multi: true,
    },
  ],
})
export class BffCustomerWebSharedCoreModule {}
