import { NgModule } from '@angular/core';
import { ActionStatusResolverService } from '@bff/shared/domain';

import { ActionStatusResolverService as ActionStatusResolverServiceAdapter } from './action-status-resolver.service';

@NgModule({
  providers: [
    {
      provide: ActionStatusResolverService,
      useClass: ActionStatusResolverServiceAdapter,
    },
  ],
})
export class AdaptersModule {}
