import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeCountDownPipe } from './pipes/time-count-down.pipe';
import { AccountNameTransformPipe } from './pipes/account-name-transform.pipe';

@NgModule({
  declarations: [AccountNameTransformPipe, TimeCountDownPipe],
  imports: [CommonModule],
  exports: [AccountNameTransformPipe, TimeCountDownPipe],
})
export class SharedDomainModule {}
