import { AbstractAuthInterceptor } from '@bff/shared/core';
import { Injectable } from '@angular/core';
import { AuthFacade } from '@bff/shared/auth/data-access';
import { NotificationFacade } from '@bff/shared/data-access-notification';

@Injectable()
export class CustomerAuthInterceptor extends AbstractAuthInterceptor {
  constructor(
    sharedAuthFacade: AuthFacade,
    private notificationFacade: NotificationFacade
  ) {
    super(sharedAuthFacade);
  }

  logout(): void {
    this.notificationFacade.unsubscribeFromNotificationServiceAndChannel();
  }
}
