export enum AdminDynamicFilterType {
  TaskStatus = 'taskStatus',
  TaskType = 'taskType',
  Tenant = 'tenant',
  Reference = 'reference',
  CreationDateFrom = 'creationDateFrom',
  CreationDateTo = 'creationDateTo',
  Assignee = 'assignee',
  Applicant = 'applicant',
  BillingAccount = 'billingAccount',
  DeliveryAccount = 'deliveryAccount',
  DelayedDays = 'delayedDays',
  MobileUser = 'mobileUser',
  UserStatus = 'userStatus',
  ServiceUser = 'serviceUser',
  CreatedAt = 'createdAt',
  TaskDateRange = 'taskDateRange',
  Representative = 'representative',
  OrderStatus = 'orderStatus',
  InternalDescription = 'internalDescription',
  Title = 'title',
  ErpOrderNumber = 'erpOrderNumber',
}
