import { Pipe, PipeTransform } from '@angular/core';
import {
  DeliveryAccount,
  BillingAccount,
  DetailedBillingAccount,
  BaseAccountInfo,
} from '@bff/shared/domain';

@Pipe({
  name: 'accountNameTransform',
})
export class AccountNameTransformPipe implements PipeTransform {
  transform(
    data:
      | DeliveryAccount
      | BillingAccount
      | DetailedBillingAccount
      | BaseAccountInfo
  ): string {
    return `${data.name} ${this.resolveAccount(data)}`;
  }

  resolveAccount(
    data:
      | DeliveryAccount
      | BillingAccount
      | DetailedBillingAccount
      | BaseAccountInfo
  ): string {
    return data.accountId ? `(${data.accountId})` : '';
  }
}
