import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    NxModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
  ],
})
export class StoreRootModule {
  static forRoot(
    prodMode: boolean = false
  ): ModuleWithProviders<StoreRootModule> {
    return {
      ngModule: StoreRootModule,
      providers: [
        ...(StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: prodMode, // Restrict extension to log-only mode
        }).providers as Provider[]),
      ],
    };
  }
}
