import {
  AuthorizationFailPayload,
  AuthorizationPayload,
  AuthorizationSuccessPayload,
  GetTokensSuccessPayload,
  InitPasswordResetFailPayload,
  InitPasswordResetPayload,
  ResetPasswordFailPayload,
  ResetPasswordPayload,
} from '@bff/shared/auth/domain';
import { Action } from '@ngrx/store';

export namespace fromAuthActions {
  export enum Types {
    Login = '[Auth] Login',
    LoginSuccess = '[Auth] Login Success',
    LoginFail = '[Auth] Login Fail',
    RefreshToken = '[Auth] Refresh Token',
    RefreshTokenSuccess = '[Auth] Refresh Token Success',
    RefreshTokenFail = '[Auth] Refresh Token Fail',
    Logout = '[Auth] Logout',
    ResetPassword = '[Auth] Reset Password',
    ResetPasswordSuccess = '[Auth] Reset Password Success',
    ResetPasswordFail = '[Auth] Reset Password Fail',
    ClearResetPasswordError = '[Auth] Clear Reset Password Error',
    LoginWithStoredToken = '[Auth] Login With Stored Token',
    LoginWithStoredTokenSuccess = '[Auth] Login With Stored Token Success',
    LoginWithStoredTokenFail = '[Auth] Login With Stored Token Fail',
    InitPasswordReset = '[Auth] Init Password Resset',
    InitPasswordResetSuccess = '[Auth] Init Password Resset Success',
    InitPasswordResetFail = '[Auth] Init Password Resset Fail',
    LoginWithRefreshToken = '[Auth] Login With Refresh Token',
    GetStoredTokensSuccess = '[Auth] Get Stored Tokens Success',
  }

  export class Login implements Action {
    readonly type = Types.Login;

    constructor(public payload: AuthorizationPayload) {}
  }

  export class LoginSuccess implements Action {
    readonly type = Types.LoginSuccess;

    constructor(public payload: AuthorizationSuccessPayload) {}
  }

  export class LoginFail implements Action {
    readonly type = Types.LoginFail;

    constructor(public payload: AuthorizationFailPayload) {}
  }

  export class RefreshToken implements Action {
    readonly type = Types.RefreshToken;
  }

  export class RefreshTokenSuccess implements Action {
    readonly type = Types.RefreshTokenSuccess;

    constructor(public payload: AuthorizationSuccessPayload) {}
  }

  export class RefreshTokenFail implements Action {
    readonly type = Types.RefreshTokenFail;

    constructor(public payload: AuthorizationFailPayload) {}
  }

  export class Logout implements Action {
    readonly type = Types.Logout;
  }

  export class ResetPassword implements Action {
    readonly type = Types.ResetPassword;

    constructor(public payload: ResetPasswordPayload) {}
  }

  export class ResetPasswordSuccess implements Action {
    readonly type = Types.ResetPasswordSuccess;
  }

  export class ResetPasswordFail implements Action {
    readonly type = Types.ResetPasswordFail;

    constructor(public payload: ResetPasswordFailPayload) {}
  }

  export class ClearResetPasswordError implements Action {
    readonly type = Types.ClearResetPasswordError;
  }

  export class LoginWithStoredToken implements Action {
    readonly type = Types.LoginWithStoredToken;
  }

  export class LoginWithStoredTokenSuccess implements Action {
    readonly type = Types.LoginWithStoredTokenSuccess;

    constructor(public payload: AuthorizationSuccessPayload) {}
  }

  export class LoginWithStoredTokenFail implements Action {
    readonly type = Types.LoginWithStoredTokenFail;

    constructor(public payload: AuthorizationFailPayload) {}
  }

  export class InitPasswordReset implements Action {
    readonly type = Types.InitPasswordReset;

    constructor(public payload: InitPasswordResetPayload) {}
  }

  export class InitPasswordResetSuccess implements Action {
    readonly type = Types.InitPasswordResetSuccess;
  }

  export class InitPasswordResetFail implements Action {
    readonly type = Types.InitPasswordResetFail;

    constructor(public payload: InitPasswordResetFailPayload) {}
  }

  export class LoginWithRefreshToken implements Action {
    readonly type = Types.LoginWithRefreshToken;
  }

  export class GetStoredTokenSuccess implements Action {
    readonly type = Types.GetStoredTokensSuccess;

    constructor(public payload: GetTokensSuccessPayload) {}
  }

  export type CollectiveType =
    | Login
    | LoginSuccess
    | LoginFail
    | RefreshToken
    | RefreshTokenSuccess
    | RefreshTokenFail
    | Logout
    | ResetPassword
    | ResetPasswordSuccess
    | ResetPasswordFail
    | LoginWithStoredToken
    | LoginWithStoredTokenSuccess
    | LoginWithStoredTokenFail
    | InitPasswordReset
    | InitPasswordResetSuccess
    | InitPasswordResetFail
    | LoginWithRefreshToken
    | ClearResetPasswordError
    | GetStoredTokenSuccess;
}
