import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreateFcmRegistrationRequestPayload } from '../resources/request-payloads/create-fcm-registration.request-payload';
import { RemoveFcmRegistrationRequestPayload } from '../resources/request-payloads/remove-fcm-registration.request-payload';
import { environment } from '@bff/shared/config';
import { urlFactory } from '@valueadd/typed-urls';
import { catchApiError } from '@bff/shared/util-rx';

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService {
  readonly endpoints = {
    createFcmRegistration: `${environment.notificationApiUrl}/device-info`,
    removeFcmRegistration: urlFactory<'id'>(
      `${environment.notificationApiUrl}/device-info/:id`,
      true
    ),
  };

  constructor(private http: HttpClient) {}

  createFcmRegistration(
    payload: CreateFcmRegistrationRequestPayload
  ): Observable<void> {
    return this.http
      .post<void>(this.endpoints.createFcmRegistration, payload)
      .pipe(catchApiError());
  }

  removeFcmRegistration(
    payload: RemoveFcmRegistrationRequestPayload
  ): Observable<void> {
    return this.http
      .delete<void>(
        this.endpoints.removeFcmRegistration.url({ id: payload.deviceId }),
        {
          headers: {
            Authorization: `Bearer ${[payload.accessToken]}`,
          },
        }
      )
      .pipe(catchApiError());
  }
}
