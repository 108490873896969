import { fromNotificationActions } from './notification.actions';
import { ApiError } from '@bff/shared/domain';

export const NOTIFICATION_FEATURE_KEY = 'notification';

export interface NotificationState {
  fcmRegistrationLoading: boolean;
  fcmRegistrationLoadError: ApiError | null;
  fcmRegistrationCreating: boolean;
  fcmRegistrationCreateError: ApiError | null;
  fcmRegistrationRemoving: boolean;
  fcmRegistrationRemoveError: ApiError | Error | null;
}

export interface NotificationPartialState {
  readonly [NOTIFICATION_FEATURE_KEY]: NotificationState;
}

export const initialState: NotificationState = {
  fcmRegistrationLoading: false,
  fcmRegistrationLoadError: null,
  fcmRegistrationCreating: false,
  fcmRegistrationCreateError: null,
  fcmRegistrationRemoving: false,
  fcmRegistrationRemoveError: null,
};

export function notificationReducer(
  state: NotificationState = initialState,
  action: fromNotificationActions.CollectiveType
): NotificationState {
  switch (action.type) {
    case fromNotificationActions.Types.CreateFcmRegistration: {
      state = {
        ...state,
        fcmRegistrationCreating: true,
        fcmRegistrationCreateError: null,
      };
      break;
    }

    case fromNotificationActions.Types.CreateFcmRegistrationFail: {
      state = {
        ...state,
        fcmRegistrationCreating: false,
        fcmRegistrationCreateError: action.payload,
      };
      break;
    }

    case fromNotificationActions.Types.CreateFcmRegistrationSuccess: {
      state = {
        ...state,
        fcmRegistrationCreating: false,
        fcmRegistrationCreateError: null,
      };
      break;
    }

    case fromNotificationActions.Types.RemoveFcmRegistration: {
      state = {
        ...state,
        fcmRegistrationRemoving: true,
        fcmRegistrationRemoveError: null,
      };
      break;
    }

    case fromNotificationActions.Types.RemoveFcmRegistrationFail: {
      state = {
        ...state,
        fcmRegistrationRemoving: false,
        fcmRegistrationRemoveError: action.payload,
      };
      break;
    }

    case fromNotificationActions.Types.RemoveFcmRegistrationSuccess: {
      state = {
        ...state,
        fcmRegistrationRemoving: false,
        fcmRegistrationRemoveError: null,
      };
      break;
    }
  }

  return state;
}
