import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeCountDown',
})
export class TimeCountDownPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    const duration = moment.duration(value, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('HH:mm');
  }
}
