import { Injectable } from '@angular/core';
import {
  AuthorizationPayload,
  AuthorizationSuccessPayload,
  InitPasswordResetPayload,
  ResetPasswordPayload,
} from '@bff/shared/auth/domain';
import { ActionStatusResolverService } from '@bff/shared/domain';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { fromAuthActions } from './auth.actions';
import { AuthPartialState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, first, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  refreshTokenInProgress$ = this.store.pipe(
    select(authQuery.getRefreshTokenInProgress)
  );
  refreshTokenError$ = this.store.pipe(select(authQuery.getRefreshTokenError));
  accessToken$ = this.store.pipe(select(authQuery.getAccessToken));
  refreshToken$ = this.store.pipe(select(authQuery.getRefreshToken));
  loginError$ = this.store.pipe(select(authQuery.getLoginError));
  loginInProgress$ = this.store.pipe(select(authQuery.getLoginInProgress));
  resetPasswordError$ = this.store.pipe(
    select(authQuery.getResetPasswordError)
  );
  resetPasswordInProgress$ = this.store.pipe(
    select(authQuery.getResetPasswordInProgress)
  );
  initPasswordResetError$ = this.store.pipe(
    select(authQuery.getInitPasswordError)
  );
  initPasswordResetInProgress$ = this.store.pipe(
    select(authQuery.getInitPasswordResetInProgress)
  );

  constructor(
    private store: Store<AuthPartialState>,
    private actions$: Actions,
    private actionStatusResolverService: ActionStatusResolverService
  ) {}

  login(data: AuthorizationPayload): Observable<void> {
    return this.actionStatusResolverService.resolve(
      new fromAuthActions.Login(data),
      fromAuthActions.Types.LoginSuccess,
      fromAuthActions.Types.LoginFail
    );
  }

  logout(): Observable<void> {
    this.store.dispatch(new fromAuthActions.Logout());
    return this.actions$.pipe(
      ofType(fromAuthActions.Types.Logout),
      timeout(1000),
      first(),
      catchError(() => of(null))
    );
  }

  initPasswordReset(data: InitPasswordResetPayload): Observable<void> {
    return this.actionStatusResolverService.resolve(
      new fromAuthActions.InitPasswordReset(data),
      fromAuthActions.Types.InitPasswordResetSuccess,
      fromAuthActions.Types.InitPasswordResetFail
    );
  }

  resetPassword(data: ResetPasswordPayload): Observable<void> {
    return this.actionStatusResolverService.resolve(
      new fromAuthActions.ResetPassword(data),
      fromAuthActions.Types.ResetPasswordSuccess,
      fromAuthActions.Types.ResetPasswordFail
    );
  }

  refreshToken(): Observable<AuthorizationSuccessPayload> {
    return this.actionStatusResolverService.resolve(
      new fromAuthActions.RefreshToken(),
      fromAuthActions.Types.RefreshTokenSuccess,
      fromAuthActions.Types.RefreshTokenFail
    );
  }

  loginWithStoredToken(): Observable<void> {
    return this.actionStatusResolverService.resolve(
      new fromAuthActions.LoginWithStoredToken(),
      fromAuthActions.Types.LoginWithStoredTokenSuccess,
      fromAuthActions.Types.LoginWithStoredTokenFail
    );
  }

  clearResetPasswordError(): Observable<void> {
    this.store.dispatch(new fromAuthActions.ClearResetPasswordError());
    return this.actions$.pipe(
      ofType(fromAuthActions.Types.ClearResetPasswordError),
      timeout(1000),
      first(),
      catchError(() => of(null))
    );
  }
}
