// @TODO why is it here

export enum OrderMenuAction {
  CancelOrder = 'CANCEL_ORDER',
  FavouriteProduct = 'FAVOURITE_PRODUCT',
  FavouriteArticles = 'FAVOURITE_ARTICLES',
  CancellationRequest = 'CANCELLATION_REQUEST',
  EditOrderDraft = 'EDIT_ORDER_DRAFT',
  DeleteOrderDraft = 'DELETE_ORDER_DRAFT',
  Reorder = 'REORDER',
}
