import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export type PartialRecordOfStrings<T> = Partial<Record<keyof T, string>>;

export function flattenPropertyArrays<R>(): OperatorFunction<
  any | null,
  PartialRecordOfStrings<R> | null
> {
  return (source$) =>
    source$.pipe(
      map((object: any | null) => {
        if (object) {
          return Object.keys(object).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = object[key]?.join('.') || '';
            return acc;
          }, <PartialRecordOfStrings<R>>{});
        } else {
          return null;
        }
      })
    );
}
