import { User } from '../types/user.type';
import { UpdateUserRequestPayload } from '../payloads/update-user.request-payload';
import { BaseInfo } from '../models/base-info.model';
import { DetailedBillingAccount } from '../models/detailed-billing-account.model';
import { DeliveryAccount } from '../models/delivery-account.model';
import { BaseAccountInfo } from '../models/base-account-info.model';
import { Representative } from '../models/representative.model';
import { UpdateRepresentativeRequestPayload } from '../payloads/update-representative.request-payload';

export class UserUtil {
  static fullName(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }

  static mapUserToUpdateUserRequestPayload(
    user: User
  ): UpdateUserRequestPayload {
    const { billingAccounts, deliveryAccounts, ...rest } = user;
    return {
      ...rest,
      billingAccountIds: this.mapBaseInfoToId(user.billingAccounts),
      deliveryAccountIds: this.mapBaseInfoToId(user.deliveryAccounts),
    };
  }

  static mapBaseInfoToId(array: BaseInfo[]): string[] {
    return array ? array.map((item: BaseInfo) => item.id) : [];
  }

  static mapBillingAccountsToBaseInfoList(
    accounts: DetailedBillingAccount[]
  ): BaseAccountInfo[] {
    return accounts.map((account) => {
      return {
        id: account.id,
        name: account.name,
        accountId: account.accountId,
      };
    });
  }

  static mapDeliveryAccountsToBaseInfoList(
    billingAccounts: DetailedBillingAccount[]
  ): BaseAccountInfo[] {
    const deliveryAccounts: BaseAccountInfo[] = [];

    billingAccounts.forEach((billingAccount: DetailedBillingAccount) => {
      deliveryAccounts.push(
        ...billingAccount.deliveryAccounts.map(
          (deliveryAccount: DeliveryAccount) => ({
            id: deliveryAccount.id,
            name: deliveryAccount.name,
            accountId: deliveryAccount.accountId,
          })
        )
      );
    });

    return deliveryAccounts;
  }

  static mapDeliveryAccountListToIdList(list: DeliveryAccount[]): string[] {
    return list.map((account: DeliveryAccount) => account.id);
  }

  static mapRepresentativeToUpdateRepresentativeRequestPayload(
    representative: Representative
  ): UpdateRepresentativeRequestPayload {
    const { deliveryAccounts, ...rest } = representative;
    return {
      ...rest,
      deliveryAccountIds: this.mapDeliveryAccountListToIdList(
        representative.deliveryAccounts
      ),
    };
  }
}
