import { switchMap } from 'rxjs/operators';
import { Observable, of, OperatorFunction } from 'rxjs';

import { ApiError } from '../interfaces/api-error.interface';

export class ApiErrorUtil {
  static translateApiMessage(
    translateFn: (key: string) => Observable<string>
  ): OperatorFunction<ApiError | null, string | null> {
    return (input$) =>
      input$.pipe(
        switchMap((v) => {
          const message = v?.message || null;
          if (message) {
            return translateFn(message);
          }
          return of(null);
        })
      );
  }
}
