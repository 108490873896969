import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const getLoginError = createSelector(getAuthState, (state) => state.loginError);

const getAccessToken = createSelector(
  getAuthState,
  (state) => state.accessToken
);

const getLoginInProgress = createSelector(
  getAuthState,
  (state) => state.loginInProgress
);

const getRefreshToken = createSelector(
  getAuthState,
  (state) => state.refreshToken
);

const getRefreshTokenError = createSelector(
  getAuthState,
  (state) => state.refreshTokenError
);

const getRefreshTokenInProgress = createSelector(
  getAuthState,
  (state) => state.refreshTokenInProgress
);

const getResetPasswordInProgress = createSelector(
  getAuthState,
  (state) => state.resetPasswordInProgress
);

const getInitPasswordResetInProgress = createSelector(
  getAuthState,
  (state) => state.initPasswordResetInProgress
);

const getInitPasswordError = createSelector(
  getAuthState,
  (state) => state.initPasswordResetError
);

const getResetPasswordError = createSelector(
  getAuthState,
  (state) => state.resetPasswordError
);

export const authQuery = {
  getLoginError,
  getAccessToken,
  getLoginInProgress,
  getRefreshToken,
  getRefreshTokenError,
  getRefreshTokenInProgress,
  getResetPasswordInProgress,
  getResetPasswordError,
  getInitPasswordError,
  getInitPasswordResetInProgress,
};
