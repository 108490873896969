import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthorizationPayload,
  AuthorizationResponse,
  AuthorizationSuccessPayload,
  GetTokensSuccessPayload,
  InitPasswordResetPayload,
  ResetPasswordPayload,
} from '@bff/shared/auth/domain';
import { environment } from '@bff/shared/config';
import { catchApiError } from '@bff/shared/util-rx';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export function getAuthServiceHeaders(): HttpHeaders {
  return new HttpHeaders().append(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );
}

export function getAuthServiceDefaultBody(): URLSearchParams {
  const body = new URLSearchParams();
  body.set('client_id', CLIENT_ID);
  body.set('client_secret', environment.clientSecret);
  return body;
}

export const TOKENS_KEY = 'TOKENS';
export const CLIENT_ID = 'account';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  readonly endpoints = {
    login: `${environment.authUrl}/auth/realms/broring/protocol/openid-connect/token`,
    resetPassword: `${environment.apiUrl}/reset-password/apply`,
    initPasswordReset: `${environment.apiUrl}/reset-password/request`,
  };

  constructor(private http: HttpClient) {}

  clearTokens(): Observable<void> {
    localStorage.removeItem(TOKENS_KEY);
    return of(undefined);
  }

  login(
    payload: AuthorizationPayload
  ): Observable<AuthorizationSuccessPayload> {
    const headers: HttpHeaders = getAuthServiceHeaders();
    const body: URLSearchParams = getAuthServiceDefaultBody();
    body.append('username', payload.login);
    body.append('password', payload.password);
    body.append('grant_type', 'password');

    return this.http
      .post<AuthorizationResponse>(this.endpoints.login, body.toString(), {
        headers,
      })
      .pipe(
        map((res: AuthorizationResponse) => {
          return {
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
          };
        }),
        catchApiError()
      );
  }

  initPasswordReset(payload: InitPasswordResetPayload): Observable<void> {
    return this.http
      .post<void>(this.endpoints.initPasswordReset, payload)
      .pipe(catchApiError());
  }

  resetPassword(payload: ResetPasswordPayload): Observable<void> {
    return this.http
      .post<void>(this.endpoints.resetPassword, payload)
      .pipe(catchApiError());
  }

  refreshToken(refreshToken: string): Observable<AuthorizationSuccessPayload> {
    const headers: HttpHeaders = getAuthServiceHeaders();
    const body = getAuthServiceDefaultBody();
    body.set('refresh_token', refreshToken);
    body.set('grant_type', 'refresh_token');

    return this.http
      .post<AuthorizationResponse>(this.endpoints.login, body.toString(), {
        headers,
      })
      .pipe(
        map((res: AuthorizationResponse) => {
          return {
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
          };
        }),
        catchApiError()
      );
  }

  getStoredAccessToken(): Observable<string | null> {
    return this.getStoredTokens().pipe(
      map((tokens) => tokens?.accessToken || null)
    );
  }

  getStoredRefreshToken(): Observable<string | null> {
    return this.getStoredTokens().pipe(
      map((tokens) => tokens?.refreshToken || null)
    );
  }

  getStoredTokens(): Observable<GetTokensSuccessPayload | null> {
    const tokens = localStorage.getItem(TOKENS_KEY);
    if (!!tokens) {
      return of(JSON.parse(tokens));
    } else {
      return of(null);
    }
  }

  saveTokens({
    accessToken,
    refreshToken,
  }: AuthorizationSuccessPayload): Observable<void> {
    localStorage.setItem(
      TOKENS_KEY,
      JSON.stringify({
        accessToken,
        refreshToken,
      })
    );
    return of(undefined);
  }
}
