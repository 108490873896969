export enum OrderTaskStatus {
  InTransmission = 'IN_TRANSMISSION',
  Transferred = 'TRANSFERED',
  InProcessing = 'IN_PROCESSING',
  InProgress = 'IN_PROGRESS',
  Approved = 'APPROVED',
  InDelivery = 'IN_DELIVERY',
  Delivered = 'DELIVERED',
  Canceled = 'CANCELED',
  CancellationRequested = 'CANCELLATION_REQUESTED',
}
