import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthorizationFailPayload,
  AuthorizationSuccessPayload,
  InitPasswordResetFailPayload,
  ResetPasswordFailPayload,
} from '@bff/shared/auth/domain';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AuthDataService } from '../services/auth-data.service';

import { fromAuthActions } from './auth.actions';
import { AUTH_FEATURE_KEY, AuthPartialState } from './auth.reducer';

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.dp.fetch(fromAuthActions.Types.Login, {
    run: (action: fromAuthActions.Login, state: AuthPartialState) =>
      this.authDataService.login(action.payload).pipe(
        switchMap((res: AuthorizationSuccessPayload) => {
          if (action.payload.rememberMe) {
            this.authDataService.saveTokens(res);
          }
          return of(new fromAuthActions.LoginSuccess(res));
        })
      ),
    onError: (
      action: fromAuthActions.Login,
      error: AuthorizationFailPayload
    ) => {
      return new fromAuthActions.LoginFail(error);
    },
  });

  @Effect()
  initPasswordReset$ = this.dp.fetch(fromAuthActions.Types.InitPasswordReset, {
    run: (action: fromAuthActions.InitPasswordReset, state: AuthPartialState) =>
      this.authDataService.initPasswordReset(action.payload).pipe(
        switchMap(() => {
          return of(new fromAuthActions.InitPasswordResetSuccess());
        })
      ),
    onError: (
      action: fromAuthActions.InitPasswordReset,
      error: InitPasswordResetFailPayload
    ) => {
      return new fromAuthActions.InitPasswordResetFail(error);
    },
  });

  @Effect()
  resetPassword$ = this.dp.fetch(fromAuthActions.Types.ResetPassword, {
    run: (action: fromAuthActions.ResetPassword, state: AuthPartialState) =>
      this.authDataService.resetPassword(action.payload).pipe(
        switchMap(() => {
          return of(new fromAuthActions.ResetPasswordSuccess());
        })
      ),
    onError: (
      action: fromAuthActions.ResetPassword,
      error: ResetPasswordFailPayload
    ) => {
      return new fromAuthActions.ResetPasswordFail(error);
    },
  });

  @Effect()
  refreshToken$ = this.dp.fetch(fromAuthActions.Types.RefreshToken, {
    run: (action: fromAuthActions.RefreshToken, state: AuthPartialState) => {
      const refreshToken: string | null = state[AUTH_FEATURE_KEY].refreshToken;
      if (!refreshToken) {
        return of(
          new fromAuthActions.RefreshTokenFail(this.getTokenExpiredPayload())
        );
      }
      return this.authDataService.refreshToken(refreshToken).pipe(
        switchMap((res) => {
          if (state[AUTH_FEATURE_KEY].rememberMe) {
            this.authDataService.saveTokens(res);
          }
          return of(new fromAuthActions.RefreshTokenSuccess(res));
        })
      );
    },
    onError: (
      action: fromAuthActions.RefreshToken,
      error: AuthorizationFailPayload
    ) => {
      this.authDataService.clearTokens();
      return new fromAuthActions.RefreshTokenFail(error);
    },
  });

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(fromAuthActions.Types.Logout),
    tap(() => {
      this.authDataService.clearTokens();
    })
  );

  @Effect()
  loginWithRefreshToken$ = this.dp.fetch(
    fromAuthActions.Types.LoginWithRefreshToken,
    {
      run: (
        action: fromAuthActions.LoginWithRefreshToken,
        state: AuthPartialState
      ) => {
        return this.authDataService.getStoredRefreshToken().pipe(
          switchMap((refreshToken) => {
            if (!refreshToken) {
              return of(
                new fromAuthActions.LoginWithStoredTokenFail(
                  this.getTokenExpiredPayload()
                )
              );
            }
            return this.authDataService.refreshToken(refreshToken).pipe(
              switchMap((res: AuthorizationSuccessPayload) => {
                return this.authDataService
                  .saveTokens(res)
                  .pipe(
                    map(
                      () => new fromAuthActions.LoginWithStoredTokenSuccess(res)
                    )
                  );
              })
            );
          })
        );
      },
      onError: (
        action: fromAuthActions.LoginWithRefreshToken,
        error: HttpErrorResponse
      ) => {
        return this.authDataService
          .clearTokens()
          .pipe(
            map(
              () =>
                new fromAuthActions.LoginWithStoredTokenFail(
                  this.getTokenExpiredPayload()
                )
            )
          );
      },
    }
  );

  @Effect()
  loginWithStoredToken$ = this.dp.fetch(
    fromAuthActions.Types.LoginWithStoredToken,
    {
      run: (
        action: fromAuthActions.LoginWithStoredToken,
        state: AuthPartialState
      ) => {
        return this.authDataService.getStoredTokens().pipe(
          switchMap((payload) => {
            if (!payload) {
              return this.authDataService.clearTokens().pipe(
                map(
                  () =>
                    new fromAuthActions.LoginWithStoredTokenFail({
                      error: '',
                      error_description: '',
                    })
                )
              );
            }
            const { accessToken, refreshToken } = payload;
            return of(
              new fromAuthActions.LoginWithStoredTokenSuccess({
                accessToken,
                refreshToken,
              })
            );
          })
        );
      },
      onError: (
        action: fromAuthActions.LoginWithStoredToken,
        error: HttpErrorResponse
      ) => {
        return this.authDataService
          .clearTokens()
          .pipe(
            map(
              () =>
                new fromAuthActions.LoginWithStoredTokenFail(
                  this.getTranslatedError('shared.unknown')
                )
            )
          );
      },
    }
  );

  constructor(
    private dp: DataPersistence<AuthPartialState>,
    private actions$: Actions,
    private authDataService: AuthDataService,
    private translationService: TranslocoService
  ) {}

  private getTokenExpiredPayload(): AuthorizationFailPayload {
    return this.getTranslatedError('shared.auth.tokenExpired');
  }

  private getTranslatedError(key: string): AuthorizationFailPayload {
    const error = key;
    const error_description = this.translationService.translate(error);
    return {
      error,
      error_description,
    };
  }
}
