import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  initialState as notificationInitialState,
  NOTIFICATION_FEATURE_KEY,
  notificationReducer,
} from './+state/notification.reducer';
import { NotificationEffects } from './+state/notification.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTIFICATION_FEATURE_KEY, notificationReducer, {
      initialState: notificationInitialState,
    }),
    EffectsModule.forFeature([NotificationEffects]),
  ],
})
export class SharedDataAccessNotificationModule {}
