import { Action } from '@ngrx/store';
import { CreateFcmRegistrationRequestPayload } from '../resources/request-payloads/create-fcm-registration.request-payload';
import { RemoveFcmRegistrationRequestPayload } from '../resources/request-payloads/remove-fcm-registration.request-payload';
import { ApiError } from '@bff/shared/domain';

export namespace fromNotificationActions {
  export enum Types {
    NotificationReceived = '[Notification] Notification Received',
    CreateFcmRegistration = '[Notification] Create Fcm Registration',
    CreateFcmRegistrationFail = '[Notification] Create Fcm Registration Fail',
    CreateFcmRegistrationSuccess = '[Notification] Create Fcm Registration Success',
    RemoveFcmRegistration = '[Notification] Remove Fcm Registration',
    RemoveFcmRegistrationFail = '[Notification] Remove Fcm Registration Fail',
    RemoveFcmRegistrationSuccess = '[Notification] Remove Fcm Registration Success',
    SubscribeToNotificationChannel = '[Notification] Subscribe To Notification Channel ',
    UnsubscribeFromNotificationChannel = '[Notification] Unsubscribe From Notification Channel ',
    UnsubscribeFromNotificationService = '[Notification] Unsubscribe From Notification Service',
  }

  export class CreateFcmRegistration implements Action {
    readonly type = Types.CreateFcmRegistration;

    constructor(public payload: CreateFcmRegistrationRequestPayload) {}
  }

  export class CreateFcmRegistrationFail implements Action {
    readonly type = Types.CreateFcmRegistrationFail;

    constructor(public payload: ApiError) {}
  }

  export class CreateFcmRegistrationSuccess implements Action {
    readonly type = Types.CreateFcmRegistrationSuccess;
  }

  export class RemoveFcmRegistration implements Action {
    readonly type = Types.RemoveFcmRegistration;

    constructor(public payload: RemoveFcmRegistrationRequestPayload) {}
  }

  export class RemoveFcmRegistrationFail implements Action {
    readonly type = Types.RemoveFcmRegistrationFail;

    constructor(public payload: ApiError | Error) {}
  }

  export class RemoveFcmRegistrationSuccess implements Action {
    readonly type = Types.RemoveFcmRegistrationSuccess;

    constructor(public payload: RemoveFcmRegistrationRequestPayload) {}
  }

  export class SubscribeToNotificationChannel implements Action {
    readonly type = Types.SubscribeToNotificationChannel;
  }

  export class UnsubscribeFromNotificationChannel implements Action {
    readonly type = Types.UnsubscribeFromNotificationChannel;
  }

  export class UnsubscribeFromNotificationService implements Action {
    readonly type = Types.UnsubscribeFromNotificationService;
  }

  export class NotificationReceived implements Action {
    readonly type = Types.NotificationReceived;
  }

  export type CollectiveType =
    | CreateFcmRegistration
    | CreateFcmRegistrationFail
    | CreateFcmRegistrationSuccess
    | RemoveFcmRegistration
    | RemoveFcmRegistrationFail
    | RemoveFcmRegistrationSuccess
    | SubscribeToNotificationChannel
    | UnsubscribeFromNotificationChannel
    | UnsubscribeFromNotificationService
    | NotificationReceived;
}
