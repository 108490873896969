import { environment } from '@bff/shared/config';

export function loadEnvironment(onSuccess: () => void): void {
  const req = new XMLHttpRequest();
  req.overrideMimeType('application/json');
  req.open(
    'GET',
    window.location.origin + '/assets/env/environment.json',
    true
  );
  req.onload = () => {
    if (req.status === 200) {
      const res = JSON.parse(req.responseText);
      environment.apiUrl = res.apiUrl;
      environment.authUrl = res.authUrl;
      environment.notificationApiUrl = res.notificationApiUrl;
      environment.fileServiceUrl = res.fileServiceUrl;
      environment.synchronizationUrl = res.synchronizationUrl;
      environment.clientSecret = res.clientSecret;
      environment.salesAppUrlScheme = res.salesAppUrlScheme;
      environment.offlineSynchronization = false;

      onSuccess();
    } else {
      console.error(
        "An error occured during loading of environment configuration file. App can't be started."
      );
    }
  };
  req.send();
}
