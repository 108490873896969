import { Actions, CreateEffectMetadata } from '@ngrx/effects';
import { ObjectType } from 'typeorm';
import { Action, Store } from '@ngrx/store';

export abstract class NgrxDatabaseSyncService {
  abstract writeToDbEffectFactory<K, T extends Function = Function, U = any>(
    actionTypes: string[],
    saveKeys: (keyof K)[],
    entity: ObjectType<T>,
    storeKey: string,
    actions$: Actions<Action>,
    store$: Store<U>,
    userData?: boolean,
    debounceTime?: number
  ): CreateEffectMetadata;

  abstract loadToDbEffectFactory<S, T extends Function = Function>(
    actionTypes: string[],
    entity: ObjectType<T>,
    actions$: Actions,
    successFn: (state: Partial<S>) => Action,
    userData?: boolean
  ): CreateEffectMetadata;
}
