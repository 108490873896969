import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AvailableLangs,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';

import { TranslocoHttpLoader } from './transloco-http.loader';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  exports: [TranslocoModule],
})
export class SharedUtilTranslocoConfigModule {
  static forRoot(
    prodMode: boolean = false,
    availableLangs: AvailableLangs = [
      { id: 'en', label: 'English' },
      { id: 'de', label: 'Deutsch' },
      { id: 'nl', label: 'Dutch' },
    ]
  ): ModuleWithProviders {
    return {
      ngModule: SharedUtilTranslocoConfigModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs,
            defaultLang: 'en',
            fallbackLang: 'en',
            prodMode,
            reRenderOnLangChange: true, // set to true when dynamic language change is in place
            flatten: {
              aot: prodMode,
            },
          }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
      ],
    };
  }
}
