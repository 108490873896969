// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { version } from 'package-json';
import { Environment } from './environment.interface';

export const environment: Environment = {
  authUrl: 'http://app-auth.broring-dev.vadev.net',
  apiUrl: 'http://app-sales.broring-dev.vadev.net',
  notificationApiUrl: 'http://app-notification.broring-dev.vadev.net',
  fileServiceUrl: 'http://app-file.broring-dev.vadev.net',
  synchronizationUrl: 'http://app-orchestrator.broring-dev.vadev.net',
  clientSecret: '85e4fc03-813f-492b-bc6e-9bb3b89bec4e',
  production: false,
  salesAppUrlScheme: 'broering-dev',
  firebase: {
    apiKey: 'AIzaSyAN2uQnjOSlbWcGRczEZa2hknSXV3De0UQ',
    authDomain: 'broering-api.firebaseapp.com',
    databaseURL: 'https://broering-api.firebaseio.com',
    projectId: 'broering-api',
    storageBucket: 'broering-api.appspot.com',
    messagingSenderId: '1038171126027',
    appId: '1:1038171126027:web:f34fbcbcbf3fc25c798a0e',
  },
  offlineSynchronization: true,
  version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
