import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class MainIonNavService {
  mainIonNavRef!: IonNav;

  initMainIonNav(ionNav: IonNav): void {
    this.mainIonNavRef = ionNav;
  }
}
