import {
  AuthorizationFailPayload,
  InitPasswordResetFailPayload,
  ResetPasswordFailPayload,
} from '@bff/shared/auth/domain';

import { fromAuthActions } from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  loginInProgress: boolean;
  loginError: AuthorizationFailPayload | null;
  accessToken: string | null;
  refreshToken: string | null;
  refreshTokenInProgress: boolean;
  refreshTokenError: AuthorizationFailPayload | null;
  resetPasswordInProgress: boolean;
  resetPasswordError: ResetPasswordFailPayload | null;
  rememberMe: boolean;
  initPasswordResetError: InitPasswordResetFailPayload | null;
  initPasswordResetInProgress: boolean;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  loginError: null,
  accessToken: null,
  refreshToken: null,
  loginInProgress: false,
  refreshTokenError: null,
  refreshTokenInProgress: false,
  resetPasswordError: null,
  resetPasswordInProgress: false,
  rememberMe: false,
  initPasswordResetError: null,
  initPasswordResetInProgress: false,
};

export function authReducer(
  state: AuthState = initialState,
  action: fromAuthActions.CollectiveType
): AuthState {
  switch (action.type) {
    case fromAuthActions.Types.Login: {
      state = {
        ...state,
        loginError: null,
        loginInProgress: true,
        rememberMe: action.payload.rememberMe,
      };
      break;
    }

    case fromAuthActions.Types.LoginSuccess: {
      state = {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loginInProgress: false,
      };
      break;
    }

    case fromAuthActions.Types.LoginFail: {
      state = {
        ...state,
        accessToken: null,
        refreshToken: null,
        loginError: action.payload,
        loginInProgress: false,
        rememberMe: false,
      };
      break;
    }

    case fromAuthActions.Types.RefreshToken: {
      state = {
        ...state,
        refreshTokenError: null,
        refreshTokenInProgress: true,
      };
      break;
    }

    case fromAuthActions.Types.RefreshTokenSuccess: {
      state = {
        ...state,
        refreshTokenInProgress: false,
        refreshTokenError: null,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
      break;
    }

    case fromAuthActions.Types.RefreshTokenFail: {
      state = {
        ...state,
        refreshTokenError: action.payload,
        refreshTokenInProgress: false,
        rememberMe: false,
        accessToken: null,
        refreshToken: null,
      };
      break;
    }

    case fromAuthActions.Types.Logout: {
      state = {
        ...state,
        accessToken: null,
        refreshToken: null,
        rememberMe: false,
        loginInProgress: false,
      };
      break;
    }

    case fromAuthActions.Types.ResetPassword: {
      state = {
        ...state,
        resetPasswordError: null,
        resetPasswordInProgress: true,
      };
      break;
    }

    case fromAuthActions.Types.ResetPasswordSuccess: {
      state = {
        ...state,
        resetPasswordInProgress: false,
      };
      break;
    }

    case fromAuthActions.Types.ResetPasswordFail: {
      state = {
        ...state,
        resetPasswordInProgress: false,
        resetPasswordError: action.payload,
      };
      break;
    }

    case fromAuthActions.Types.InitPasswordReset: {
      state = {
        ...state,
        initPasswordResetError: null,
        initPasswordResetInProgress: true,
      };
      break;
    }

    case fromAuthActions.Types.InitPasswordResetSuccess: {
      state = {
        ...state,
        initPasswordResetInProgress: false,
      };
      break;
    }

    case fromAuthActions.Types.InitPasswordResetFail: {
      state = {
        ...state,
        initPasswordResetInProgress: false,
        initPasswordResetError: action.payload,
      };
      break;
    }

    case fromAuthActions.Types.LoginWithStoredToken: {
      state = {
        ...state,
        loginError: null,
        loginInProgress: true,
        rememberMe: true,
      };
      break;
    }

    case fromAuthActions.Types.LoginWithStoredTokenSuccess: {
      state = {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loginInProgress: false,
      };
      break;
    }

    case fromAuthActions.Types.LoginWithStoredTokenFail: {
      state = {
        ...state,
        accessToken: null,
        refreshToken: null,
        loginError: action.payload,
        loginInProgress: false,
        rememberMe: false,
      };
      break;
    }

    case fromAuthActions.Types.LoginWithRefreshToken: {
      state = {
        ...state,
        loginError: null,
        loginInProgress: true,
        rememberMe: true,
      };
      break;
    }

    case fromAuthActions.Types.ClearResetPasswordError: {
      state = {
        ...state,
        resetPasswordError: null,
      };
      break;
    }

    case fromAuthActions.Types.GetStoredTokensSuccess: {
      state = {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }
  }

  return state;
}
