import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { fromNotificationActions } from './notification.actions';
import { NotificationPartialState } from './notification.reducer';
import { NotificationDataService } from '../services/notification-data.service';
import { map } from 'rxjs/operators';
import { ApiError } from '@bff/shared/domain';
import { fromAuthActions } from '@bff/shared/auth/data-access';

@Injectable()
export class NotificationEffects {
  @Effect()
  createFcmRegistration$ = this.dp.pessimisticUpdate(
    fromNotificationActions.Types.CreateFcmRegistration,
    {
      run: (action: fromNotificationActions.CreateFcmRegistration) => {
        return this.notificationDataService
          .createFcmRegistration(action.payload)
          .pipe(
            map(
              (data) =>
                new fromNotificationActions.CreateFcmRegistrationSuccess()
            )
          );
      },
      onError: (
        action: fromNotificationActions.CreateFcmRegistration,
        error: ApiError
      ) => {
        return new fromNotificationActions.CreateFcmRegistrationFail(error);
      },
    }
  );

  @Effect()
  removeFcmRegistration$ = this.dp.pessimisticUpdate(
    fromNotificationActions.Types.RemoveFcmRegistration,
    {
      run: (action: fromNotificationActions.RemoveFcmRegistration) => {
        return this.notificationDataService
          .removeFcmRegistration(action.payload)
          .pipe(
            map(
              (data) =>
                new fromNotificationActions.RemoveFcmRegistrationSuccess(
                  action.payload
                )
            )
          );
      },
      onError: (
        action: fromNotificationActions.RemoveFcmRegistration,
        error: ApiError
      ) => {
        return new fromNotificationActions.RemoveFcmRegistrationFail(error);
      },
    }
  );

  @Effect()
  unsubscribeFromNotificationServiceSuccessOrFail$ = this.actions$.pipe(
    ofType(fromNotificationActions.Types.UnsubscribeFromNotificationService),
    map(() => new fromAuthActions.Logout())
  );

  constructor(
    private actions$: Actions,
    private dp: DataPersistence<NotificationPartialState>,
    private notificationDataService: NotificationDataService
  ) {}
}
