import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NotificationPartialState } from './notification.reducer';
import { notificationQuery } from './notification.selectors';
import { fromNotificationActions } from './notification.actions';
import { CreateFcmRegistrationRequestPayload } from '../resources/request-payloads/create-fcm-registration.request-payload';
import { RemoveFcmRegistrationRequestPayload } from '../resources/request-payloads/remove-fcm-registration.request-payload';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  fcmRegistrationCreating$ = this.store.pipe(
    select(notificationQuery.getFcmRegistrationCreating)
  );
  fcmRegistrationCreateError$ = this.store.pipe(
    select(notificationQuery.getFcmRegistrationCreateError)
  );
  fcmRegistrationRemoving$ = this.store.pipe(
    select(notificationQuery.getFcmRegistrationRemoving)
  );
  fcmRegistrationRemoveError$ = this.store.pipe(
    select(notificationQuery.getFcmRegistrationRemoveError)
  );
  constructor(private store: Store<NotificationPartialState>) {}

  createFcmRegistration(data: CreateFcmRegistrationRequestPayload): void {
    this.store.dispatch(
      new fromNotificationActions.CreateFcmRegistration(data)
    );
  }

  removeFcmRegistration(data: RemoveFcmRegistrationRequestPayload): void {
    this.store.dispatch(
      new fromNotificationActions.RemoveFcmRegistration(data)
    );
  }

  unsubscribeFromNotificationServiceAndChannel(): void {
    this.store.dispatch(
      new fromNotificationActions.UnsubscribeFromNotificationService()
    );
    this.store.dispatch(
      new fromNotificationActions.UnsubscribeFromNotificationChannel()
    );
  }
}
